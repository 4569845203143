body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
form{
  width:100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a {
  text-decoration: unset;
  color:inherit
}

.react-datepicker-wrapper{
  width: 100%;
}

/* antrenman planları sayfasındaki calendara ait cssler */
.rbc-allday-cell {
  display: none !important;
}

.rbc-button-link  {
  color: #111927;
  text-align: center;
  font-style: normal;
  font-size: 12px;
  font-family: Inter;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-feature-settings: 'clig' off, 'liga' off;
}

em{
  color:  #6C737F;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 24px; 
letter-spacing: 0.15px;
}



.my-calendar .rbc-time-content {
  border-top: unset;
  
}


/* .my-calendar .rbc-button-link{
  padding: 10px;
} */


.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid #ddd !important;

}



.rbc-label,
.rbc-time-header-gutter {
  min-width: 54.6875px !important;
  border-bottom: 1px solid #ddd;
}



.rbc-event-label, .rbc-event-content{
  color:#111927 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 150% !important;
  font-style: normal !important;
  font-family: Inter !important;
  padding-right: 24px !important;
  padding-left: 24px !important;

}

.my-calendar .rbc-today{
  background-color: white !important;
}

.rbc-timeslot-group{
  border-bottom: unset !important;
}

.rbc-header {
  border-bottom: unset !important;
}